import { createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { initialState } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'

// Create a transform to only persist brokers
const selectivePersistTransform = createTransform(
    // Transform state coming into storage
    (inboundState: any) => {
        if (!inboundState.brokers || inboundState.brokers.length === 0) {
            return undefined // Skip persistence entirely if brokers are empty
        }
        return {
            brokers: inboundState.brokers,
            unmatchedBrokers: inboundState.unmatchedBrokers,
            previousFetchTime: inboundState.previousFetchTime,
            brokerStats: inboundState.brokerStats
        }
    },
    // Transform state coming out of storage
    (outboundState: any) => {
        return outboundState
            ? {
                  ...initialState,
                  brokers: outboundState.brokers,
                  unmatchedBrokers: outboundState.unmatchedBrokers,
                  previousFetchTime: outboundState.previousFetchTime,
                  brokerStats: outboundState.brokerStats
              }
            : initialState // Fallback to initialState if nothing was stored
    },
    {
        whitelist: ['peopleFinder']
    }
)

export const persistBrokers = {
    key: 'brokers',
    storage,
    whitelist: ['peopleFinder'], // Only persist peopleFinder reducer
    transforms: [selectivePersistTransform]
}
