import {
  asProfilePost,
  createProfileSchema,
  emptyProfileSchema,
  fromProfileFields,
} from '@meprism/shared/src/schemas/ProfileForm'
import {
  BaseProfileState,
  FetchProfile,
  PostProfile,
} from '@meprism/shared/src/redux/profile/ProfileSlice'
import { BaseThunkExtra } from '@meprism/shared'
import {
  useForm,
  UseFormProps,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Logger } from '@meprism/app-utils'
import { useDispatch } from 'react-redux'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { z } from 'zod'
import { useFormData } from './FormDataHelper'
const profileSchema = createProfileSchema(false, false, false, false, false)

export type ProfileSchemaType = z.TypeOf<typeof profileSchema>

export type ProfileFormHookReturn = ReturnType<
  typeof useForm<ProfileSchemaType>
>

export interface UseProfileFormProps {
  form?: UseFormProps<ProfileSchemaType>
  onSubmitSuccess?: () => void
  onSubmitError?: (error: any) => void
  middleNameOptional?: boolean
  linkedinOptional?: boolean
  streetOptional?: boolean
  zipOptional?: boolean
  agreeToTermsOptional?: boolean | undefined
}

export const useProfileForm = (props: UseProfileFormProps) => {
  const { setFormValue } = useFormData()
  const dispatch =
    useDispatch<ThunkDispatch<BaseProfileState, BaseThunkExtra, AnyAction>>()
    const getDefaultValues = async () => {
      try {
        const profile = await dispatch(FetchProfile()).unwrap()
        return fromProfileFields({
          ...profile,
          additional_information: profile.additional_information || {
            additional_first_name: [],
            maiden_name: []
          }
        })
      } catch (error) {
        Logger.error(`Error fetching profile: ${error}`)
        return emptyProfileSchema()
      }
    }

  const submitHandler: SubmitHandler<ProfileSchemaType> = async (values) => {
    try {
      const payload = asProfilePost({
        ...values,
        agreeToTerms: !!values.agreeToTerms,
      })

      await dispatch(PostProfile(payload))

      // Set form data to the context
      setFormValue('profile', values) // Use the correct key and data

      if (props.onSubmitSuccess) {
        props.onSubmitSuccess()
      }
    } catch (error) {
      Logger.error(`Error submitting profile update: ${error}`)
      if (props.onSubmitError) {
        props.onSubmitError(error)
      }
    }
  }

  const {
    register,
    setValue,
    watch,
    setFocus,
    formState: { errors },
    control,
    handleSubmit,
    trigger,
  } = useForm<ProfileSchemaType>({
    resolver: zodResolver(
      createProfileSchema(
        Boolean(props.middleNameOptional),
        Boolean(props.linkedinOptional),
        Boolean(props.streetOptional),
        Boolean(props.agreeToTermsOptional),
        Boolean(props.zipOptional),
      ),
    ),
    defaultValues: getDefaultValues,
  })

  const firstNameArray = useFieldArray({
    control,
    name: 'firstName' as const,
  })

  const otherFirstNameArray = useFieldArray({
    control,
    name: 'otherFirstName' as const,
  })

  const otherMaidenNameArray = useFieldArray({
    control,
    name: 'otherMaidenName' as const,
  })

  const middleNameArray = useFieldArray({
    control,
    name: 'middleName' as const,
  })

  const lastNameArray = useFieldArray({
    control,
    name: 'lastName' as const,
  })

  const addressArray = useFieldArray({
    control,
    name: 'address' as const,
  })

  const emailArray = useFieldArray({
    control,
    name: 'email' as const,
  })

  const phoneNumberArray = useFieldArray({
    control,
    name: 'phoneNumber' as const,
  })

  return {
    register,
    setValue,
    watch,
    errors,
    setFocus,
    control,
    handleSubmit,
    submitHandler,
    firstNameArray,
    middleNameArray,
    lastNameArray,
    otherFirstNameArray,
    otherMaidenNameArray,
    addressArray,
    emailArray,
    phoneNumberArray,
    trigger,
  }
}
