import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MpTypography } from '../shared'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ScanWidget } from './HomeViews/ScanWidget'
import useMediaQuery from '@mui/material/useMediaQuery'
import { BrokerListSuspense } from './HomeViews/BrokerListSuspense'
import PaymentDeclinedWidget from '../shared/molecule/PaymentDeclinedWidget'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  hasPaymentFailed,
  selectPlatformStatus,
} from '../../redux/product/ProductSlice'
import ConsentAgreementModal from '../shared/molecule/ConsentAgreementModal'
import {
  CURRENT_AUTH_LETTER_VERSION,
  PostProfile,
  selectProfileSlice,
  selectShouldShowAuthorizationModal,
} from '@meprism/shared/src/redux/profile/ProfileSlice'

const Home = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()
  const platformStatus = useAppSelector(selectPlatformStatus)
  const paymentDeclined = hasPaymentFailed(platformStatus)
  const isOnDetails = location.pathname.includes('broker')
  const profile = useAppSelector(selectProfileSlice)
  const shouldShowAuthModal = useAppSelector(selectShouldShowAuthorizationModal)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/dashboard')
    }, 250)
    return () => clearTimeout(timeout)
  }, [navigate])

  useEffect(() => {
    if (shouldShowAuthModal) {
      setOpen(true)
    }
  }, [shouldShowAuthModal])

  return (
    <Box>
      <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '28px' }}>
        <Grid container columnSpacing={35} rowSpacing={5}>
          {paymentDeclined && (
            <Grid item xs={12}>
              <PaymentDeclinedWidget />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              my: 8,
            }}>
            <MpTypography variant={'h1'}>Privacy Dashboard</MpTypography>
            <ScanWidget />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{ display: isMobile && isOnDetails ? 'none' : 'block' }}>
            <MpTypography variant={'h2'} sx={{ mb: 6 }}>
              Websites selling your data
            </MpTypography>
            <BrokerListSuspense />
          </Grid>
          <Outlet />
        </Grid>
      </Container>
      <ConsentAgreementModal
        open={open}
        setOpen={setOpen}
        name={name}
        setName={setName}
        textInputProps={{ label: 'Signature' }}
        extra={
          <Typography variant="body2">
            In order to effectively remove your data from the internet, we ask
            that you sign the following document.
          </Typography>
        }
        onSubmit={() =>
          dispatch(
            PostProfile({
              names: profile.profile.names,
              emails: profile.profile.emails,
              address: profile.profile.address,
              birthdate: profile.profile.birthdate,
              phones: profile.profile.phones,
              mp_auth_letter_signature: name,
              auth_letter_s3_key: profile.profile.auth_letter_s3_key,
              gender: profile.profile.gender,
              auth_letter_version: CURRENT_AUTH_LETTER_VERSION,
              linkedIn: profile.profile.linkedIn,
              additional_information: profile.profile.additional_information || {
                additional_first_name: [],
                maiden_name: []
              }
            }),
          )
        }
      />
    </Box>
  )
}

export default Home
