import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from '../../../theme/DashboardTheme'
import CenteredTabs from '../../Views/Tabs'
import RemovalStats from './RemovalStats'
import OptOuts from './OptOuts'
import { useAppSelector } from '../../../redux/storeExports'
import { selectActiveGrant } from '@meprism/shared/src/redux/product/ProductSlice'
import { Grant } from '@meprism/shared/src/redux/product/ProductTypes'

export interface StatsProp {
  grant: Grant | undefined
}

const DashboardGrid = () => {
  const [tabValue, setTabValue] = useState('Removal Stats')
  const grant = useAppSelector(selectActiveGrant)
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  // ToDo: We will remove this check once blast emails are sent out to all those subscribers who have purchased a grant before 2025-01-21
  const isGrantDateValid =
    !grant?.active ||
    (grant?.grant_purchase_date &&
      new Date(grant.grant_purchase_date) > new Date('2025-01-21'))

  return (
    <ThemeProvider theme={theme}>
      <CenteredTabs
        value={tabValue}
        handleChange={handleTabChange}
        isGrantDateValid={isGrantDateValid}
      />

      {tabValue === 'Removal Stats' ? (
        <RemovalStats grant={grant} />
      ) : (
        <OptOuts grant={grant} />
      )}
    </ThemeProvider>
  )
}

export default DashboardGrid
