import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import React, {useState} from 'react'
import {useAppDispatch} from '../../../app/hooks'
import {useNavigate} from 'react-router-dom'
import {deleteUserAccount} from '@meprism/shared/src/redux/authentication/authenticationSlice'

const styles = {
  bulletIcon: {
    fontSize: '8px',
    color: 'black',
  },
  delAcc: {
    background: '#C67EFF',
    border: '1px solid #c67eff',
    color: 'white !important',
    '&:hover': {
      color: '#C67EFF !important',
      background: 'white',
    },
    '&:disabled': {
      color: 'white !important',
      background: ' #e0c4f6',
    },
  },
  listIconWidth: {
    minWidth: '35px',
  },
  listItem: {
    py: '4px'
  }
}

const DeleteAcc = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isOpenDialog, setOpenDialog] = useState(false)

  const handleDeleteAcc = async () => {
    await dispatch(deleteUserAccount())
    navigate('/login')
  }

  const closeDeleteAccDialog = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography variant="h3">Delete Account</Typography>
      </Grid>

      <Grid item md={12}>
        <Button sx={styles.delAcc} onClick={() => setOpenDialog(true)}>
          Delete Account
        </Button>
      </Grid>
      <Dialog
        open={isOpenDialog}
        onClose={closeDeleteAccDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{borderRadius: '50px'}}>
        <DialogTitle>
          Are you sure you want to delete your account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action is irreversible. Your account will be deleted
            permanently.
          </DialogContentText>
          <DialogContentText>
            <Grid item md={12}>
              <List>
                <ListItem sx={styles.listItem}>
                  <ListItemIcon sx={styles.listIconWidth}>
                    <CircleIcon sx={styles.bulletIcon}/>
                  </ListItemIcon>
                  <ListItemText>
                    This will permanently delete your mePrism account and you will
                    lose all your opt out progress & profile info.
                  </ListItemText>
                </ListItem>
                <ListItem sx={styles.listItem}>
                  <ListItemIcon sx={styles.listIconWidth}>
                    <CircleIcon sx={styles.bulletIcon}/>
                  </ListItemIcon>
                  <ListItemText>
                    We will no longer be supporting you to remove your personal
                    information from the internet.
                  </ListItemText>
                </ListItem>
                <ListItem sx={styles.listItem}>
                  <ListItemIcon sx={styles.listIconWidth}>
                    <CircleIcon sx={styles.bulletIcon}/>
                  </ListItemIcon>
                  <ListItemText>
                    The authorization agreement will be deemed terminated.
                  </ListItemText>
                </ListItem>
                <ListItem sx={styles.listItem}>
                  <ListItemIcon sx={styles.listIconWidth}>
                    <CircleIcon sx={styles.bulletIcon}/>
                  </ListItemIcon>
                  <ListItemText>This action can not be undone.</ListItemText>
                </ListItem>
              </List>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={closeDeleteAccDialog}>
              Cancel
            </Button>
            <Button size="small" variant="outlined" color="error" onClick={handleDeleteAcc}>
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteAcc
