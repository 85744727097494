import {
  Box,
  Button,
  Card,
  Collapse,
  Container,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, {useRef, useState, useEffect} from 'react'
import {MpTypography} from '../../shared'
import nexticon from '@meprism/shared/src/assets/icons/next.svg'
import {
  Controller,
  FieldValues,
  UseFieldArrayReturn,
  UseFormWatch,
} from 'react-hook-form'
import shieldicon from '@meprism/shared/src/assets/icons/shieldgrey.svg'
import deleteIcon from '@meprism/shared/src/assets/icons/Delete.svg'
import CountryCode from '@meprism/shared/src/assets/icons/countrycode.svg'
import {DatePicker} from '@mui/x-date-pickers'
import {
  ProfileFormHookReturn,
  ProfileSchemaType,
  useProfileForm,
} from '../../../utils/hooks/useProfileForm'
import MainHeader from '../../shared/organism/MainHeader'
import {theme} from '../../../theme/DashboardTheme'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import SuccessSubmitModal from '../../shared/atom/SuccessSubmitModal'
import ErrorSubmitModal from '../../shared/atom/ErrorSubmitModal'
import PlusOne from '../../shared/atom/PlusOne'
import {
  fetchFamilyStatus,
  selectActiveGrant,
} from '@meprism/shared/src/redux/product/ProductSlice'
import {store} from '../../../redux/store'
import {useAppSelector} from '../../../redux/storeExports'
import {Toast} from '../../toast/toast'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import {UpdateLinkedinUrl} from "@meprism/shared/src/redux/profile/ProfileSlice";
import {useAppDispatch} from "../../../app/hooks";

interface SidebarProps {
  sections: string[]
  onSectionChange: (sectionIndex: number) => void
}

interface PersonalInfoSectionProps {
  firstNameArray: UseFieldArrayReturn<ProfileSchemaType, 'firstName', 'id'>
  middleNameArray: UseFieldArrayReturn<ProfileSchemaType, 'middleName', 'id'>
  lastNameArray: UseFieldArrayReturn<ProfileSchemaType, 'lastName', 'id'>
  otherFirstNameArray: UseFieldArrayReturn<
    ProfileSchemaType,
    'otherFirstName',
    'id'
  >
  otherMaidenNameArray: UseFieldArrayReturn<
    ProfileSchemaType,
    'otherMaidenName',
    'id'
  >
  register: ProfileFormHookReturn['register']
  trigger: ProfileFormHookReturn['trigger']
  errors: ProfileFormHookReturn['formState']['errors']
  control?: ProfileFormHookReturn['control']
  setValue: ProfileFormHookReturn['setValue']
  watch: UseFormWatch<ProfileSchemaType>
}

interface LocationsAddressesSectionProps {
  addressArray: UseFieldArrayReturn<ProfileSchemaType, 'address', 'id'>
  register: ProfileFormHookReturn['register']
  errors: ProfileFormHookReturn['formState']['errors']
  control?: ProfileFormHookReturn['control']
}

interface ContactInfoSectionProps {
  emailArray: UseFieldArrayReturn<ProfileSchemaType, 'email', 'id'>
  phoneNumberArray: UseFieldArrayReturn<ProfileSchemaType, 'phoneNumber', 'id'>
  register: ProfileFormHookReturn['register']
  errors: ProfileFormHookReturn['formState']['errors']
  control?: ProfileFormHookReturn['control']
  setValue: ProfileFormHookReturn['setValue']
}

const styles = {
  inputContainer: {
    width: '100%',
    marginLeft: '0px',
  },
  header: {
    color: '#040115',
    mb: 5,
  },
  fullWidth: {
    width: '100%',
  },
  required: {
    '&:after': {
      content: '"*"',
      color: '#615F6B',
    },
  },
  textField: {
    background: '#EFEDFDB2',
  },

  mb1: {
    marginBlock: '1rem',
  },
  borderBlack: {
    borderColor: '#a5a1a1',
  },
} as const

export const profileLandingLoader = () => {
  store.dispatch(fetchFamilyStatus())
  return null
}

const PersonalInfoSection: React.FC<PersonalInfoSectionProps> = ({
                                                                   register,
                                                                   trigger,
                                                                   errors,
                                                                   control,
                                                                   firstNameArray,
                                                                   lastNameArray,
                                                                   middleNameArray,
                                                                   otherFirstNameArray,
                                                                   otherMaidenNameArray,
                                                                   setValue,
                                                                   watch,
                                                                 }) => {
  const linkedInValue = watch('linkedIn');
  const originalLinkedInRef = useRef('');
  const [hasChanged, setHasChanged] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (linkedInValue && originalLinkedInRef.current === '') {
      originalLinkedInRef.current = linkedInValue;
    }
    setHasChanged(linkedInValue !== originalLinkedInRef.current);
  }, [linkedInValue]);

  const handleLinkedinUpdate = async () => {
    if (linkedInValue && hasChanged) {
      try {
        await dispatch(UpdateLinkedinUrl(linkedInValue));
        originalLinkedInRef.current = linkedInValue;
        setHasChanged(false);
        Toast.show({
          type: 'success',
          text1: 'LinkedIn URL has been updated successfully',
        });
      } catch (error) {
        Toast.show({
          type: 'error',
          text1: 'Failed to update LinkedIn URL',
        });
      }
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <>
      <ThemeProvider theme={theme}>
        {!isMobile && (
          <Typography variant="h3" sx={styles.header}>
            Basic Info
          </Typography>
        )}
        <Stack spacing={3} display={'flex'} flexDirection={'column'}>
          <Typography variant="h5" color={'#615F6B'} mb={5}>
            We use your information to look for additional records of yours.
            Additional information like alternative names helps us find more of
            your data.
          </Typography>

          <Stack spacing={3} display={'flex'} flexDirection={['column', 'row']}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{width: '100%'}}>
              {firstNameArray.fields[0] && (
                <Grid item md={3} sm={12} sx={{width: '100%'}}>
                  <Typography
                    variant="h4"
                    color={'#615F6B'}
                    sx={styles.required}>
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    disabled={true}
                    defaultValue={''}
                    error={!!errors?.firstName?.[0]?.value}
                    helperText={errors?.firstName?.[0]?.value?.message}
                    InputLabelProps={{shrink: true}}
                    {...register(`firstName.${0}.value` as const)}
                    onChange={(e) => {
                      firstNameArray.update(0, {value: e.target.value})
                    }}
                  />
                </Grid>
              )}
              {middleNameArray.fields[0] && (
                <Grid item md={3} sm={12} sx={{width: '100%'}}>
                  <Typography
                    variant="h4"
                    color={'#615F6B'}
                    sx={styles.inputContainer}>
                    Middle Name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    defaultValue={''}
                    error={!!errors?.middleName?.[0]?.value}
                    helperText={errors?.middleName?.[0]?.value?.message}
                    InputLabelProps={{shrink: true}}
                    {...register(`middleName.${0}.value` as const)}
                  />
                </Grid>
              )}
              {lastNameArray.fields[0] && (
                <Grid item md={3} sm={12} sx={{width: '100%'}}>
                  <Typography
                    variant="h4"
                    color={'#615F6B'}
                    sx={styles.required}>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={true}
                    error={!!errors?.lastName?.[0]?.value}
                    helperText={errors?.lastName?.[0]?.value?.message}
                    InputLabelProps={{shrink: true}}
                    defaultValue={''}
                    {...register(`lastName.${0}.value` as const)}
                  />
                </Grid>
              )}
            </Grid>
          </Stack>
          <Stack spacing={3} display={'flex'} flexDirection={['column', 'row']}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent={'space-between'}
              sx={{width: '100%'}}>
              <Grid item md={9} sm={12} sx={{width: '100%'}}>
                <Typography variant="h4" color={'#615F6B'}>
                  LinkedIn URL
                </Typography>
                <TextField
                  fullWidth
                  // disabled={true}
                  defaultValue={linkedInValue}
                  error={!!errors?.linkedIn}
                  helperText={errors?.linkedIn?.message}
                  InputLabelProps={{shrink: true}}
                  {...register('linkedIn', {
                    onChange: async (e) => {
                      setValue('linkedIn', e.target.value)
                      await trigger('linkedIn')
                    },
                    onBlur: async () => {
                      await trigger('linkedIn')
                    },
                  })}
                  style={{backgroundColor: MpTheme.colors.white}}
                />
              </Grid>
              <Grid item md={3} sm={12} sx={{width: '100%'}}>
                <Button
                  variant={'contained'}
                  color={'darkPurple'}
                  disabled={!hasChanged}
                  sx={{
                    height: 40,
                    marginTop: 4,
                    borderRadius: '10px',
                    boxShadow: 'unset',
                    border: '2px solid #C67EFF',
                    background: 'transparent',
                    color: '#C67EFF',
                    '&.Mui-disabled': {
                      background: 'transparent',
                      color: '#A0A0A4',
                      border: '2px solid #A0A0A4',
                      opacity: 0.7,
                    },
                    ':hover': {
                      background: 'transparent',
                      color: '#C67EFF',
                    },
                  }}
                  onClick={handleLinkedinUpdate}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={2} display={'flex'} flexDirection={['column', 'row']}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{width: '100%'}}>
              <Grid item md={3} sm={12} sx={{width: '100%'}} mb={5}>
                <Typography variant="h4" color={'#615F6B'} sx={styles.required}>
                  Birthdate
                </Typography>
                <Controller
                  name={'birthdate'}
                  control={control}
                  render={({field}) => (
                    <DatePicker
                      disabled={true}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={!!errors?.birthdate}
                          helperText={errors?.birthdate?.message ?? ''}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={Object.assign({}, styles.borderBlack, styles.mb1)}/>
          <Stack spacing={3} display={'flex'} flexDirection={'column'}>
            <Typography variant="h3" sx={styles.header}>
              Other Names
            </Typography>
            <Typography variant="h5" mb={10}>
              Optional: You can include alternative names like nicknames, maiden
              names, or names from marriage changes. For example,
              &quot;Robert&quot; could be &quot;Bob&quot;. We&apos;ll use
              whatever full name you provide to conduct our search.
            </Typography>
            <Stack
              spacing={3}
              display={'flex'}
              flexDirection={['column', 'row']}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{width: '100%'}}>
                <Grid item md={3} sm={12} sx={{width: '100%'}}>
                  <Typography
                    variant="h4"
                    color={'#615F6B'}
                    // sx={styles.required}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={otherFirstNameArray.fields[0]?.value !== ''}
                    defaultValue={''}
                    InputLabelProps={{shrink: true}}
                    {...register(`otherFirstName.0.value` as const)}
                    error={!!errors?.otherFirstName?.[0]?.value}
                    helperText={
                      errors?.otherFirstName?.[0]?.value?.message || ''
                    }
                  />
                </Grid>
                <Grid item md={3} sm={12} sx={{width: '100%'}}>
                  <Typography
                    variant="h4"
                    color={'#615F6B'}
                    // sx={styles.required}
                  >
                    Maiden Name
                  </Typography>
                  <TextField
                    fullWidth
                    defaultValue={''}
                    disabled={otherMaidenNameArray.fields[0]?.value !== ''}
                    InputLabelProps={{shrink: true}}
                    {...register(`otherMaidenName.0.value` as const)}
                    error={!!errors?.otherMaidenName?.[0]?.value}
                    helperText={
                      errors?.otherMaidenName?.[0]?.value?.message || ''
                    }
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>
    </>
  )
}

const LocationsAddressesSection: React.FC<LocationsAddressesSectionProps> = ({
                                                                               addressArray,
                                                                               register,
                                                                               errors,
                                                                             }) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const handleDelete = (array: FieldValues, index: number) => {
    if (!addressArray.fields[index]?.city) {
      array.remove(index)
    }
  }
  const handleAddAddress = () => {
    if (addressArray.fields.length <= 4) {
      addressArray.append({
        state: '' as 'CA',
        city: '',
        streetAddress: '',
        zip: ''
      })
    } else {
      Toast.show({
        type: 'warning',
        text1: 'You can not add more than 4 secondary addresses.',
      })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={3}>
        <Box style={styles.inputContainer}>
          {!isMobile && (
            <Typography variant="h3" sx={styles.header}>
              Address
            </Typography>
          )}
          <Typography variant="h5" mb={10}>
            We use your information to look for additional records of yours.
            Additional information such as previous addresses and alternative
            names helps us find more of your data.
          </Typography>

          <Stack divider={<Divider sx={{mb: 4}}/>}>
            {addressArray.fields.map((field, index) => (
              <Box key={field.id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    mb: 4,
                  }}>
                  {index == 0 ? (
                    <MpTypography variant={'h4'} color={'#040115'}>
                      Primary Address
                    </MpTypography>
                  ) : (
                    <MpTypography variant={'h4'} color={'#040115'}>
                      Address {index + 1}
                    </MpTypography>
                  )}
                </Box>
                <Grid container spacing={4} sx={{width: '100%'}}>
                  <Grid item md={2} xs={12} sm={12} sx={{width: '100%'}}>
                    <Typography
                      variant="h4"
                      color={'#615F6B'}
                      sx={styles.required}>
                      City
                    </Typography>
                    <TextField
                      required
                      disabled={
                        index <= addressArray.fields.length - 1 &&
                        addressArray.fields[index].city !== ''
                      }
                      sx={{width: '100%'}}
                      error={!!errors?.address?.[index]?.city}
                      helperText={errors?.address?.[index]?.city?.message || ''}
                      InputLabelProps={{shrink: true}}
                      {...register(`address.${index}.city` as const)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={12} sx={{width: '100%'}}>
                    <Typography
                      variant="h4"
                      color={'#615F6B'}
                      sx={styles.required}>
                      State
                    </Typography>
                    <TextField
                      required
                      disabled={
                        index <= addressArray.fields.length - 1 &&
                        addressArray.fields[index].city !== ''
                      }
                      sx={{width: '100%'}}
                      error={!!errors?.address?.[index]?.state}
                      helperText={
                        errors?.address?.[index]?.state?.message || ''
                      }
                      InputLabelProps={{shrink: true}}
                      {...register(`address.${index}.state` as const, {
                        setValueAs: (v) => v.toUpperCase(),
                      })}
                    />
                  </Grid>
                  <Grid item md={2} sm={12} sx={{width: '100%'}}>
                    <Typography variant="h4" color={'#615F6B'}>
                      Zip Code
                    </Typography>
                    <TextField
                      disabled={
                        index <= addressArray.fields.length - 1 &&
                        addressArray.fields[index].zip !== ''
                      }
                      sx={{width: '100%'}}
                      error={!!errors?.address?.[index]?.zip}
                      helperText={errors?.address?.[index]?.zip?.message || ''}
                      InputLabelProps={{shrink: true}}
                      {...register(`address.${index}.zip` as const)}
                    />
                  </Grid>
                  <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <Typography variant="h4" color={'#615F6B'}>
                      Street Address
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={
                        index <= addressArray.fields.length - 1 &&
                        addressArray.fields[index].streetAddress !== ''
                      }
                      error={!!errors?.address?.[index]?.streetAddress}
                      helperText={
                        errors?.address?.[index]?.streetAddress?.message || ''
                      }
                      InputLabelProps={{shrink: true}}
                      {...register(`address.${index}.streetAddress` as const)}
                    />
                  </Grid>
                  <Grid
                    item
                    md={1}
                    sm={12}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {index != 0 && !addressArray.fields[index].city && (
                      <img
                        onClick={() => {
                          handleDelete(addressArray, index)
                        }}
                        src={deleteIcon}
                        alt="Description of the image"
                        style={{
                          cursor: 'pointer',
                          maxWidth: '50px',
                          maxHeight: '50px',
                          marginTop: '10px',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={3} sm={3} mt={5}>
                  {index === addressArray.fields.length - 1 && (
                    <Button
                      variant={'contained'}
                      sx={{
                        height: 50,
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        border: '2px solid #C67EFF',
                        background: 'transparent',
                        color: '#C67EFF',
                        '&.Mui-disabled': {
                          background: 'transparent',
                          color: '#C67EFF',
                        },
                        ':hover': {
                          background: 'transparent',
                          color: '#C67EFF',
                        },
                      }}
                      onClick={() => {
                        handleAddAddress()
                      }}>
                      Add Address
                    </Button>
                  )}
                </Grid>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </ThemeProvider>
  )
}
const ContactInfoSection: React.FC<ContactInfoSectionProps> = ({
                                                                 emailArray,
                                                                 phoneNumberArray,
                                                                 register,
                                                                 errors,
                                                               }) => {
  const handleDelete = (array: FieldValues, index: number) => {
    array.remove(index)
    array.append({value: ''})
  }
  const addSecondaryNumberOrEmail = (array: FieldValues, type: string) => {
    if (array.fields.length < 5) {
      if (array.fields.length === 0) {
        array.insert(array.fields.length, {
          value: '',
        })
        array.swap(1, 0)
      } else if (array.fields.length === 1) {
        array.insert(array.fields.length, {
          value: '',
        })
        // array.insert(0, {
        //   value: array.fields[0].value,
        // })
      } else {
        array.insert(array.fields.length, {
          value: '',
        })
      }
    } else {
      array.insert(array.fields.length, {
        value: '',
      })
      array.remove(5)
      setTimeout(() => {
        Toast.show({
          type: 'warning',
          text1:
            type === 'email'
              ? 'You can not add more than 4 secondary emails.'
              : 'You can not add more than 4 secondary numbers.',
        })
      }, 500)
    }
  }

  const isMobile = useMediaQuery('(max-width:600px)')
  return (
    <Box sx={{flexDirection: isMobile ? 'column' : 'row'}}>
      {!isMobile && (
        <Typography variant="h3" sx={styles.header}>
          Phone Number
        </Typography>
      )}

      {phoneNumberArray.fields.length ? (
        <Typography variant="h5" mb={5}>
          Primary Number :
        </Typography>
      ) : (
        <Typography variant="h5" mb={1}></Typography>
      )}

      {/* {phoneNumberArray.fields.length === 1 && (
          <Grid container spacing={2} alignItems="center" mt={2}>
            <Grid item md={6} sm={12} sx={{ width: isMobile ? '100%' : 'unset' }}>
              <TextField
                sx={{ width: '100%', background: '#fff' }}
                placeholder="Enter Number"
                disabled={true}
                error={!!errors?.email?.[0]?.value} helperText={errors?.email?.[0]?.value?.message || ''}
                value={phoneNumberArray.fields[0].value}
              />
            </Grid>
          </Grid>
        )}  */}
      {phoneNumberArray.fields.length === 0 && (
        <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item md={6} sm={12} sx={{width: isMobile ? '100%' : 'unset'}}>
            <TextField
              type="tel"
              sx={{width: '100%', background: '#fff'}}
              maxLength={10}
              error={!!errors?.phoneNumber?.[0]?.value}
              helperText={errors?.phoneNumber?.[0]?.value?.message ?? ''}
              placeholder="Enter Number"
              {...register(`phoneNumber.${0}.value` as const)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={CountryCode}
                      width="24px"
                      style={{marginRight: 2}}
                    />
                    <span style={{fontSize: '14px', color: '#757575'}}>
                      +1
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            {phoneNumberArray.fields.length == 0 && (
              <Button
                variant={'contained'}
                color={'darkPurple'}
                onClick={() =>
                  addSecondaryNumberOrEmail(phoneNumberArray, 'number')
                }
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}>
                Add Number
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {phoneNumberArray.fields.map((field, index) => (
        <Grid container key={field.id} spacing={2} alignItems="center" mt={2}>
          <Grid item md={6} sm={12} sx={{width: isMobile ? '100%' : 'unset'}}>
            <TextField
              sx={{width: '100%', background: '#fff'}}
              type="tel"
              // disabled={index===0 && phoneNumberArray.fields.length > 1}
              disabled={
                index <= phoneNumberArray.fields.length - 1 &&
                phoneNumberArray.fields[index].value !== ''
              }
              error={!!errors?.phoneNumber?.[index]?.value}
              helperText={errors?.phoneNumber?.[index]?.value?.message ?? ''}
              {...register(`phoneNumber.${index}.value` as const)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={CountryCode}
                      width="24px"
                      style={{marginRight: 2}}
                    />
                    <span style={{fontSize: '14px', color: '#757575'}}>
                      +1
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            {index === phoneNumberArray.fields.length - 1 && (
              <Button
                variant={'contained'}
                color={'darkPurple'}
                onClick={() =>
                  addSecondaryNumberOrEmail(phoneNumberArray, 'number')
                }
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}>
                Add Number
              </Button>
            )}
          </Grid>
        </Grid>
      ))}

      {phoneNumberArray.fields.length > 0 && (
        <Typography variant="body2" mt={5}>
          {phoneNumberArray.fields.map((field, indexphone) => {
            if (field.value.trim() !== '' && indexphone != 0) {
              return (
                <Grid container alignItems="center" key={indexphone}>
                  <Grid item md={3}>
                    {field.value}
                    <p style={{margin: 0, color: '#A0A0A4'}}></p>
                  </Grid>
                  <Grid item md={3}>
                    <img
                      onClick={() => handleDelete(phoneNumberArray, indexphone)}
                      src={deleteIcon}
                      alt="Description of the image"
                      style={{
                        cursor: 'pointer',
                        maxWidth: '50px',
                        maxHeight: '50px',
                        marginLeft: '10px',
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }
            return null
          })}
        </Typography>
      )}

      <Divider sx={Object.assign({}, styles.borderBlack, styles.mb1)}/>
      <Typography variant="h3" sx={styles.header}>
        Email
      </Typography>
      {emailArray.fields.length ? (
        <Typography variant="h5" mb={5}>
          Primary Email :
        </Typography>
      ) : (
        <Typography variant="h5" mb={1}></Typography>
      )}
      {/* {emailArray.fields.length===1 && (
          <Grid container spacing={2} alignItems="center" mt={2}>
            <Grid item md={6} sm={12} sx={{ width: isMobile ? '100%' : 'unset' }}>
              <TextField
                sx={{ width: '100%', background: '#fff' }}
                placeholder="Enter Email..."
                disabled={true}
                error={!!errors?.email?.[0]?.value} helperText={errors?.email?.[0]?.value?.message || ''}
                value={emailArray.fields[0].value}
              />
            </Grid>
          </Grid>
        )} */}
      {emailArray.fields.length === 0 && (
        <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item md={6} sm={12} sx={{width: isMobile ? '100%' : 'unset'}}>
            <TextField
              sx={{width: '100%', background: '#fff'}}
              placeholder="Enter Email..."
              error={!!errors?.email?.[0]?.value}
              helperText={errors?.email?.[0]?.value?.message || ''}
              {...register(`email.${0}.value` as const, {
                setValueAs: (value) => value.trim(),
              })}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            {emailArray.fields.length === 0 && (
              <Button
                variant={'contained'}
                color={'darkPurple'}
                onClick={() => addSecondaryNumberOrEmail(emailArray, 'email')}
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}>
                Add Email
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {emailArray.fields.map((field, index) => (
        <Grid container spacing={2} key={field.id} alignItems="center" mt={2}>
          <Grid item md={6} sm={12} sx={{width: isMobile ? '100%' : 'unset'}}>
            <TextField
              sx={{width: '100%', background: '#fff'}}
              placeholder="Enter Email..."
              // disabled={index===0 && emailArray.fields.length > 1}
              disabled={
                index <= emailArray.fields.length - 1 &&
                emailArray.fields[index].value !== ''
              }
              error={!!errors?.email?.[index]?.value}
              helperText={errors?.email?.[index]?.value?.message || ''}
              {...register(`email.${index}.value` as const, {
                setValueAs: (value) => value.trim(),
              })}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            {index === emailArray.fields.length - 1 && (
              <Button
                variant={'contained'}
                color={'darkPurple'}
                onClick={() => addSecondaryNumberOrEmail(emailArray, 'email')}
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}>
                Add Email
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      {emailArray.fields.length > 0 && (
        <Typography variant="body2" mt={5}>
          {emailArray.fields.map(
            (email, indexemail) =>
              email.value.trim() !== '' &&
              indexemail > 0 && (
                <Grid container alignItems="center" key={email.id}>
                  <Grid item md={6}>
                    {email.value}

                    <p style={{margin: 0, color: '#A0A0A4'}}></p>
                  </Grid>

                  <Grid item md={3}>
                    <img
                      onClick={() => handleDelete(emailArray, indexemail)}
                      src={deleteIcon}
                      alt="Description of the image"
                      style={{
                        cursor: 'pointer',
                        maxWidth: '50px',
                        maxHeight: '50px',
                        marginLeft: '10px',
                      }}
                    />
                  </Grid>
                </Grid>
              ),
          )}
        </Typography>
      )}
    </Box>
  )
}
const Sidebar: React.FC<SidebarProps> = ({sections, onSectionChange}) => {
  const [selectedSection, setSelectedSection] = useState<number>(0)
  const activeGrant = useAppSelector(selectActiveGrant)
  return (
    <Card
      sx={{
        width: '280px',
        height: '80vh',
      }}>
      <List>
        {sections.map((section, index) =>
          index === 3 &&
          activeGrant &&
          activeGrant.sku &&
          activeGrant.sku.split('.')[2] === 'fam' ? (
            <ListItemButton
              selected={index === selectedSection}
              sx={{borderBottom: '1px solid #A0A0A4'}}
              key={index}
              onClick={() => {
                setSelectedSection(index)
                onSectionChange(index)
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <div
                  style={{
                    color: index === selectedSection ? '#1A1B20' : '#615F6B',
                  }}>
                  {section}
                </div>
                <img
                  src={nexticon}
                  alt="Description of the image"
                  style={{maxWidth: '100%', maxHeight: '100%'}}
                />
              </div>
            </ListItemButton>
          ) : (
            index != 3 && (
              <ListItemButton
                selected={index === selectedSection}
                sx={{borderBottom: '1px solid #A0A0A4'}}
                key={index}
                onClick={() => {
                  setSelectedSection(index)
                  onSectionChange(index)
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <div
                    style={{
                      color: index === selectedSection ? '#1A1B20' : '#615F6B',
                    }}>
                    {section}
                  </div>
                  <img
                    src={nexticon}
                    alt="Description of the image"
                    style={{maxWidth: '100%', maxHeight: '100%'}}
                  />
                </div>
              </ListItemButton>
            )
          ),
        )}
      </List>
    </Card>
  )
}

const Profile = () => {
  const activeGrant = useAppSelector(selectActiveGrant)
  const [open, setOpen] = useState(false)
  const [openError, setOpenError] = useState(false)
  const {
    firstNameArray,
    lastNameArray,
    middleNameArray,
    otherFirstNameArray,
    otherMaidenNameArray,
    addressArray,
    emailArray,
    phoneNumberArray,
    register,
    errors,
    control,
    watch,
    setValue,
    handleSubmit,
    submitHandler,
    trigger,
  } = useProfileForm({
    onSubmitSuccess: () => {
      setOpen(true)
    },
    onSubmitError: () => {
      setOpenError(true)
    },
    middleNameOptional: true,
    streetOptional: true,
    linkedinOptional: true,
    zipOptional: true
  })
  const [currentSection, setCurrentSection] = useState(0)
  const sections = ['Basic Info', 'Address', 'Phone & Email', 'Plus One']
  const handleSectionChangeWeb = (sectionIndex: number) => {
    setCurrentSection(sectionIndex)
  }
  const [openSections, setOpenSections] = useState<number[]>([])
  const handleSectionChange = (sectionIndex: number) => {
    setOpenSections((prevOpenSections) => {
      const isOpen = prevOpenSections.includes(sectionIndex)
      return isOpen
        ? prevOpenSections.filter((item) => item !== sectionIndex)
        : [...prevOpenSections, sectionIndex]
    })
  }
  const isMobile = useMediaQuery('(max-width:600px)')
  return (
    <>
      {isMobile ? (
        <Stack
          spacing={5}
          component={'form'}
          onSubmit={handleSubmit(submitHandler)}
          flexDirection={'column'}>
          <MainHeader title={'Profile'}/>
          <Card sx={{p: 5, position: 'relative'}}>
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  {index === 3 &&
                  activeGrant &&
                  activeGrant.sku &&
                  activeGrant.sku.split('.')[2] === 'fam' ? (
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => handleSectionChange(index)}>
                      <Typography variant="body1" sx={{color: '#1A1B20'}}>
                        {section}
                      </Typography>
                      {openSections.includes(index) ? (
                        <ExpandLess/>
                      ) : (
                        <ExpandMore/>
                      )}
                    </ListItemButton>
                  ) : (
                    index != 3 && (
                      <ListItemButton
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        onClick={() => handleSectionChange(index)}>
                        <Typography variant="body1" sx={{color: '#1A1B20'}}>
                          {section}
                        </Typography>
                        {openSections.includes(index) ? (
                          <ExpandLess/>
                        ) : (
                          <ExpandMore/>
                        )}
                      </ListItemButton>
                    )
                  )}
                </ListItem>
                <Collapse
                  in={openSections.includes(index)}
                  timeout="auto"
                  unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      disablePadding
                      sx={{
                        width: 'calc(100% - 30px)',
                        marginLeft: 2,
                        marginRight: 2,
                        p: 2,
                      }}>
                      {index === 0 && (
                        <PersonalInfoSection
                          watch={watch}
                          setValue={setValue}
                          firstNameArray={firstNameArray}
                          middleNameArray={middleNameArray}
                          lastNameArray={lastNameArray}
                          otherFirstNameArray={otherFirstNameArray}
                          otherMaidenNameArray={otherMaidenNameArray}
                          register={register}
                          errors={errors}
                          control={control}
                          trigger={trigger}
                        />
                      )}
                      {index === 1 && (
                        <LocationsAddressesSection
                          addressArray={addressArray}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      )}
                      {index === 2 && (
                        <ContactInfoSection
                          setValue={setValue}
                          emailArray={emailArray}
                          phoneNumberArray={phoneNumberArray}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      )}
                      {index === 3 &&
                        activeGrant &&
                        activeGrant.sku &&
                        activeGrant?.sku.split('.')[2] === 'fam' && <PlusOne/>}
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
            <Typography variant="body2" sx={{mt: 5, pb: 3}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img
                  src={shieldicon}
                  alt="Description of the image"
                  style={{
                    maxWidth: '50px',
                    maxHeight: '50px',
                    marginRight: '10px',
                  }}
                />
                <div>
                  <p style={{margin: 0, color: '#A0A0A4'}}>
                    mePrism uses industry-standard encryption to protect
                    confidentiality of all information you provide. We do not
                    sell your data.
                  </p>
                </div>
              </div>
            </Typography>
            <SuccessSubmitModal open={open} setOpen={setOpen}/>
            <ErrorSubmitModal open={openError} setOpen={setOpenError}/>
            <Button
              variant={'contained'}
              type={'submit'}
              sx={{width: '100%'}}
              color={'darkPurple'}>
              Save Changes & Run New Scan
            </Button>
          </Card>
        </Stack>
      ) : (
        <Container sx={{mx: 0, px:0}}>
          <Stack
            component={'form'}
            onSubmit={handleSubmit(submitHandler)}>
            <MainHeader title={'Profile'}/>
            <Grid container>
              <Grid item md={3} xs={3}>
                <Sidebar
                  sections={sections}
                  onSectionChange={handleSectionChangeWeb}
                />
              </Grid>
              <Grid item md={8.7} xs={8.7} sx={{ml: 5}}>
                {' '}
                <Card
                  sx={{
                    height: '80vh',
                    p: 5,
                    position: 'relative',
                    overflow: 'auto',
                  }}>
                  <div style={{position: 'relative', minHeight: '90%'}}>
                    {currentSection === 0 && (
                      <PersonalInfoSection
                        watch={watch}
                        setValue={setValue}
                        firstNameArray={firstNameArray}
                        middleNameArray={middleNameArray}
                        lastNameArray={lastNameArray}
                        otherFirstNameArray={otherFirstNameArray}
                        otherMaidenNameArray={otherMaidenNameArray}
                        register={register}
                        errors={errors}
                        control={control}
                        trigger={trigger}
                      />
                    )}
                    {currentSection === 1 && (
                      <LocationsAddressesSection
                        addressArray={addressArray}
                        register={register}
                        errors={errors}
                        control={control}
                      />
                    )}

                    {currentSection === 2 && (
                      <ContactInfoSection
                        setValue={setValue}
                        emailArray={emailArray}
                        phoneNumberArray={phoneNumberArray}
                        register={register}
                        errors={errors}
                        control={control}
                      />
                    )}
                    {currentSection === 3 &&
                      activeGrant &&
                      activeGrant.sku &&
                      activeGrant?.sku.split('.')[2] === 'fam' && <PlusOne/>}
                    <Typography
                      variant="body2"
                      sx={{position: 'absolute', bottom: -60}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                          src={shieldicon}
                          alt="Description of the image"
                          style={{
                            maxWidth: '50px',
                            maxHeight: '50px',
                            marginRight: '10px',
                          }}
                        />
                        <div>
                          <p style={{margin: 0, color: '#A0A0A4'}}>
                            mePrism uses industry-standard encryption to protect
                            confidentiality of all information you provide. We
                            do not sell your data.
                          </p>
                        </div>
                      </div>
                    </Typography>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <ErrorSubmitModal open={openError} setOpen={setOpenError}/>
            <SuccessSubmitModal open={open} setOpen={setOpen}/>
            <Box sx={styles.inputContainer} mt={10}>
              <Button
                variant={'contained'}
                type={'submit'}
                color={'darkPurple'}>
                Save Changes & Run New Scan
              </Button>
            </Box>
          </Stack>
        </Container>
      )}
    </>
  )
}

export default Profile
