import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Grid,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { fonts, theme } from '../../../theme/DashboardTheme'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  fetchOptOuts,
  selectOptOuts,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { BrokerOptOuts } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderTypes'
import Loader from '@meprism/shared/src/components/atoms/Loader'
import { StatsProp } from './DashboardGrid'

const OptOuts = ({ grant }: StatsProp) => {
  const dispatch = useAppDispatch()
  const optouts = useAppSelector(selectOptOuts)
  const [page, setPage] = useState(0)
  const rowsPerPage = 5
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isFetching, setIsFetching] = useState(true)
  const fetchOptoutResults = useCallback(async () => {
    setIsFetching(true)
    await dispatch(fetchOptOuts())
    setIsFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchOptoutResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paginatedRows = useMemo(
    () => optouts.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [optouts, page],
  )

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage - 1)
  }

  const formatDate = (dateString: string) =>
    new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

  const columns = useMemo(
    () => [
      { key: 'broker_name', name: 'Broker' },
      { key: 'updated_at', name: 'Opt Out Submitted At' },
      { key: 'status', name: 'Status' },
    ],
    [],
  )

  const getStatusStyles = (status: string) => {
    const statusMap: Record<string, { color: string; text: string }> = {
      complete: { color: '#4CAF50', text: 'Opt Out Submitted' },
      pending: { color: '#FFC107', text: 'Opt Out Pending' },
      failed: { color: '#FFC107', text: 'Opt Out Pending' },
    }
    return statusMap[status] || { color: '#000000', text: status }
  }

  return (
    <Box
      sx={{
        '& .MuiCircularProgress-root': {
          color: '#1976D2',
          marginTop: 40,
        },
      }}>
      <Loader loading={isFetching}>
        {!isMobile ? (
          <Box
            sx={{
              width: '100%',
              pb: 2,
              bgcolor: '#fff',
              borderRadius: 2,
              p: 3,
            }}>
            <TableContainer>
              <Table sx={{ bgcolor: '#fff', borderRadius: 2 }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.key}
                        sx={{
                          borderBottom: '1px solid #9135E0',
                          ...fonts.Inter.SemiBold,
                          textAlign: 'left',
                        }}>
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedRows.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        const value = row[column.key as keyof BrokerOptOuts]
                        return (
                          <TableCell
                            key={column.key}
                            sx={{
                              borderBottom: '1px solid rgba(97, 95, 107, 0.5)',
                              textAlign: 'left',
                            }}>
                            {column.key === 'status' ? (
                              grant ? (
                                <Typography
                                  sx={{
                                    color: getStatusStyles(value as string)
                                      .color,
                                  }}>
                                  {getStatusStyles(value as string).text}
                                </Typography>
                              ) : (
                                <Link href="/subscription">
                                  Subscribe to Opt Out
                                </Link>
                              )
                            ) : column.key === 'updated_at' ? (
                              grant ? (
                                formatDate(value as string)
                              ) : (
                                <Typography
                                  sx={{
                                    color: 'transparent',
                                    textShadow: '0 0 8px rgba(0,0,0,0.5)',
                                    userSelect: 'none',
                                  }}>
                                  Blurred Content
                                </Typography>
                              )
                            ) : (
                              value
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 2,
                  p: 2,
                  borderRadius: 2,
                  bgcolor: '#fff',
                }}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={Math.ceil(optouts.length / rowsPerPage)}
                  page={page + 1}
                  onChange={handleChangePage}
                />
              </Box>
            </TableContainer>
          </Box>
        ) : (
          <Box sx={{ width: '100%', bgcolor: '#fff', borderRadius: 2, p: 3 }}>
            <Typography variant="h5" mx={2} mt={2} mb={3}>
              Opt Outs ({optouts.length})
            </Typography>

            {paginatedRows.map((row, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                sx={{ borderBottom: '1px solid #615F6B', p: 2 }}>
                {columns.map((col) => {
                  const value = row[col.key as keyof BrokerOptOuts]
                  return (
                    <Grid item key={col.key} xs={6}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">{col.name}:</Typography>

                        {col.key === 'status' ? (
                          grant ? (
                            <Typography
                              sx={{
                                color: getStatusStyles(value as string).color,
                              }}>
                              {getStatusStyles(value as string).text}
                            </Typography>
                          ) : (
                            <Link href="/subscription">
                              Subscribe to Opt Out
                            </Link>
                          )
                        ) : col.key === 'updated_at' ? (
                          grant ? (
                            <Typography>
                              {formatDate(value as string)}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: 'transparent',
                                textShadow: '0 0 8px rgba(0,0,0,0.5)',
                                userSelect: 'none',
                              }}>
                              Blurred Content
                            </Typography>
                          )
                        ) : (
                          <Typography>{value}</Typography>
                        )}
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            ))}
          </Box>
        )}
      </Loader>
    </Box>
  )
}

export default OptOuts
